import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { hasCustomClaim, redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/auth-guard';
import { canActivate } from '@angular/fire/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToMenus = () => redirectLoggedInTo(['menu']);

const routes: Routes = [
  
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  }, 
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule),
    ...canActivate(redirectLoggedInToMenus)
  },
  {
    path: 'menu',
    loadChildren: () => import('./menu/menu.module').then( m => m.MenuPageModule),
    ...canActivate(redirectUnauthorizedToLogin) 
  },
  {
    path: 'adduser',
    loadChildren: () => import('./modals/adduser/adduser.module').then( m => m.AdduserPageModule)
  },
  {
    path: 'addproduct',
    loadChildren: () => import('./modals/addproduct/addproduct.module').then( m => m.AddproductPageModule)
  },
  {
    path: 'make-order-for',
    loadChildren: () => import('./make-order-for/make-order-for.module').then( m => m.MakeOrderForPageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
